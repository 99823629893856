exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{ContentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-description-js": () => import("./../../../src/pages/service-description.js" /* webpackChunkName: "component---src-pages-service-description-js" */),
  "component---src-pages-work-contentful-work-showcase-slug-js": () => import("./../../../src/pages/work/{ContentfulWorkShowcase.slug}.js" /* webpackChunkName: "component---src-pages-work-contentful-work-showcase-slug-js" */)
}

